import { useCurrency } from '@/app/providers/currency-provider';
import MyDropdownMenu from '@/components/dropdown-menu';
import Button from '@/components/form/button';
import CheckboxField from '@/components/form/checkbox-field';
import { type CurrencyDictionary, currenciesDic } from '@/shared/constants/global';
import useUpdateCurrency from '@/shared/utils/use-update-currency';
import { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';

export const CurrencySwitcher = () => {
  const { curCurrency } = useCurrency();
  const [open, setOpen] = useState(false);

  const updateCurrency = useUpdateCurrency();

  const onChangeCurrency = async (currency: CurrencyDictionary) => {
    updateCurrency(currency);
  };

  return (
    <MyDropdownMenu
      open={open}
      setOpen={setOpen}
      button={
        <Button
          theme="clear"
          className="flex justify-between items-center gap-3 w-full border border-gray-200"
        >
          <span className="grow text-left">{curCurrency?.label}</span>
          {open ? <FaAngleUp /> : <FaAngleDown />}
        </Button>
      }
    >
      <ul className="w-[200px]">
        {currenciesDic
          .filter((currency) => currency.active)
          .map((currency) => {
            return (
              <li
                key={currency.value}
                className="border-b border-color-border-grey last:border-b-0"
              >
                <label className="flex items-center justify-between gap-3 p-4 cursor-pointer ">
                  <span className="text-sm font-medium text-color-dark-purple">
                    {currency.label}
                  </span>
                  <CheckboxField
                    onChange={() => onChangeCurrency(currency)}
                    type="radio"
                    name="currency"
                    checked={currency.value === curCurrency?.value}
                  />
                </label>
              </li>
            );
          })}
      </ul>
    </MyDropdownMenu>
  );
};
