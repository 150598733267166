import { useAuth } from '@/app/providers/auth-provider';
import { useCurrency } from '@/app/providers/currency-provider';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UPDATE_USER } from '../apollo/mutations/user/update-user';
import type { CurrencyDictionary } from '../constants/global';
import notify from './notify';

export const useUpdateCurrency = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const { setCurCurrency, setLoadingCurrency } = useCurrency();

  const [updateUser] = useMutation(UPDATE_USER);
  const { user } = useAuth();

  const updateCurrency = useCallback(async (currency: CurrencyDictionary) => {
    setLoadingCurrency?.(true);
    if (user) {
      await updateUser({
        variables: {
          id: Number.parseInt(user?.id ?? ''),
          data: {
            paymentCurrency: currency.value.toLowerCase(),
          },
        },
        onCompleted: () => {
          newSearchParams.delete('lang');
          setSearchParams(newSearchParams);
          setCurCurrency?.(currency);
          localStorage.setItem('currency', currency.value);
        },
        onError: (error) => {
          notify(error.message, 'error');
        },
      });
    } else {
      localStorage.setItem('currency', currency.value);
    }
    setLoadingCurrency?.(false);
  }, []);

  return updateCurrency;
};

export default useUpdateCurrency;
